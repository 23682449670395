// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$primary: $voltus-button-default;
$secondary: $voltus-button-default;
$success: $voltus-button-success;
$info: $voltus-button-default;
$warning: $voltus-button-warning;
$danger: $voltus-button-danger;
$light: $voltus-button-default;
$dark: $voltus-dark;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);

// Body
// Settings for the `<body>` element.
$body-bg: $voltus-background-color;
$body-color: $voltus-text-color;

// Links
// Style anchor elements.

$link-color: $voltus-link-color;
$link-hover-color: $voltus-link-color--hover;

// Components
// Define common padding and border radius sizes and more.

$border-color: $voltus-border-color;

$box-shadow: $voltus-box-shadow;

// Typography
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Inter', sans-serif;
$font-weight-normal: $font-regular;
$font-weight-bold: $font-bold;
$font-weight-bolder: $font-black;

$h1-font-size: $h1;
$h2-font-size: $h2;
$h3-font-size: $h3;
$h4-font-size: $h4;
$h5-font-size: $h5;
$h6-font-size: $h6;

$text-muted: $voltus-text-muted-color;

$blockquote-small-color: $voltus-text-muted-color;

$mark-bg: $voltus-highlight;

// Buttons + Forms
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.28125rem;
$input-btn-padding-x: 1.25rem;

$input-btn-border-width: 3px;

// Buttons

$btn-font-weight: $font-black;

$btn-link-disabled-color: $voltus-link-disabled-color;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: none;
$btn-border-radius-lg: none;
$btn-border-radius-sm: none;

// Forms

$input-disabled-bg: $voltus-input-disabled-bg;

$input-color: $voltus-text-color;
$input-border-color: $voltus-input-border-color;
$input-border-width: 2px;

$input-placeholder-color: $voltus-input-placeholder-color;

$input-height-inner-half: calc(
  #{1.5 * 0.5em} + #{$input-btn-padding-y}
) !default;

$form-check-input-border: $voltus-form-input-border;
$form-check-input-border-radius: 0;

$form-check-input-checked-color: $voltus-text-color;
$form-check-input-checked-bg-color: $color-white;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color !default;

$input-group-addon-bg: $voltus-input-group-addon-bg;

$form-select-padding-y: $input-btn-padding-y;
$form-select-padding-x: $input-btn-padding-x;
$form-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator

$form-select-disabled-color: $voltus-form-select-disabled-color;
$form-select-bg: $color-white;
$form-select-disabled-bg: $voltus-form-select-disabled-bg;
$form-select-bg-size: 16px 12px !default; // In pixels because image dimensions
$form-select-indicator-color: $voltus-form-select-indicator-color;
$form-select-indicator: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath stroke='#{$form-select-indicator-color}' stroke-width='2px' d='M2 5l6 6 6-6' fill='none' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e"),
  '#',
  '%23'
) !default;

$form-select-background: $form-select-indicator no-repeat right
  $form-select-padding-x center / $form-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$form-select-feedback-icon-padding-right: calc(
  (1em + #{2 * $form-select-padding-y}) * 3 / 4 + #{$form-select-padding-x +
    $form-select-indicator-padding}
) !default;
$form-select-feedback-icon-position: center right
  ($form-select-padding-x + $form-select-indicator-padding) !default;
$form-select-feedback-icon-size: $input-height-inner-half
  $input-height-inner-half !default;

$form-range-track-bg: $voltus-form-range-track-bg;
$form-range-thumb-disabled-bg: $voltus-form-range-thumb-disabled-bg;

$custom-control-indicator-size: 1.25rem;
$custom-control-indicator-bg: $color-white;

$custom-control-indicator-bg-size: 70% 70%;
$custom-control-indicator-box-shadow: none;
$custom-control-indicator-border-color: $voltus-input-border-color;
$custom-control-indicator-border-width: 2px;

$custom-control-indicator-checked-color: $voltus-checked-color;
$custom-control-indicator-checked-bg: $color-white;

$custom-control-indicator-checked-border-color: $voltus-input-border-color;

$custom-control-indicator-focus-border-color: $voltus-input-border-color;

$custom-checkbox-indicator-border-radius: 0;

// Form validation

$form-feedback-valid-color: $voltus-form-feedback-valid-color;
$form-feedback-invalid-color: $voltus-form-feedback-invalid-color;

// Dropdown menu container and contents.

$dropdown-padding-y: 0;
$dropdown-font-size: 0.875rem;
$dropdown-border-color: none;
$dropdown-border-radius: 0;
$dropdown-border-width: 0;
$dropdown-box-shadow: $voltus-box-shadow;

$dropdown-link-color: $voltus-text-color;
$dropdown-link-hover-color: $voltus-text-color;
$dropdown-link-hover-bg: $voltus-hover;

$dropdown-link-disabled-color: $voltus-link-disabled-color;
$dropdown-header-color: $voltus-dropdown-header-color;

// Pagination

$pagination-active-color: $color-white;
$pagination-active-bg: $voltus-selected;

// Cards

$card-cap-bg: $color-white;

// Popovers

$popover-border-color: $voltus-border-color; //this should be something else
$popover-header-bg: $color-white;

// Modals

$modal-content-border-color: none;
$modal-content-border-width: 0;
$modal-content-border-radius: none;
$modal-header-border-width: 1px;
$modal-footer-border-width: 1px;

// Alerts

$alert-colors: () !default;

$alert-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $voltus-success-alert-color,
    'info': $info,
    'warning': $voltus-warning-alert-color,
    'danger': $voltus-danger-alert-color,
    'light': $light,
    'dark': $dark,
  ),
  $alert-colors
);

// Progress bars

$progress-height: 1.5rem;

// List group

$list-group-active-color: $body-color;
$list-group-active-bg: $voltus-selected;
$list-group-action-color: $voltus-link-color;

// Breadcrumbs

$breadcrumb-bg: none;
$breadcrumb-divider-color: $voltus-breadcrumb-divider-color;
$breadcrumb-active-color: $voltus-breadcrumb-active-color;

// Calendars
$calendar-event-gray-bg: #7a8597;
$calendar-event-orange-bg: #f66042;
