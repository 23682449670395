// Vars from index.js
$borderColors: (primary: #adb8b6, secondary: #36454f, focus: #0ebf75);
$background: (primary: #fff, secondary: #f2f3f3, admin: #D7E9F0);
$greens: (0: #d5ffe9, 5: #abf4d0, 10: #84eab9, 20: #41d492, 30: #0ebf75, 40: #00a968, 50: #009460, 60: #007e56, 70: #00694b, 80: #00543f, 90: #003e31, 100: #002921);
$golds: (0: #fff4de, 5: #fee8c2, 10: #fcdda6, 20: #f7c36e, 30: #f0a73e, 40: #e58818, 50: #d76900, 60: #c55100, 70: #ae3800, 80: #922000, 90: #720d00, 100: #520100);
$reds: (0: #ffe3e1, 5: #ffd1cc, 10: #ffbfb7, 20: #ff9b8c, 30: #ff7b64, 40: #fd6042, 50: #ea492d, 60: #d3361e, 70: #b62814, 80: #951c0d, 90: #701309, 100: #4a0c06);
$grays: (0: #fff, 5: #f2f3f3, 10: #e6e8e7, 15: #d7ddda, 20: #c8d0cd, 30: #adb8b6, 40: #94a1a1, 50: #7d8789, 60: #686e72, 70: #53565a, 80: #373c42, 90: #20242a, 100: #0c0e12);
$slates: (0: #cfcfcf, 5: #bfc0c5, 10: #afb3bb, 20: #939aa9, 30: #7a8597, 40: #657385, 50: #536373, 60: #445461, 70: #36454f, 80: #2b3744, 90: #222b39, 100: #191f2e);
$yellows: (50: #ffe000, 70: #ffb700, 80: #fda64f);
$blues: (20: #579dc2, 50: #2076a5, 60: #2060a5);
$textColor: (main: #36454f, secondary: #536373, error: #d3361e, muted: #7a8597, link: #007e56, light: #ffffff);
$text: (main: #36454f, muted: #7a8597);
$boxShadows: (0 1px 3px 0 rgba(0, 0, 0, 0.15));
$color-white: #fff;
$color-black: #000;
$st-tropaz: #234F8D;
$bright-turquoise: #0de8bb;
$dodger-blue: #2d7df0;
$fresco-blue: #004964;
$lochmara: #2076a5;
$jade: #008080;
$atoll-blue: #00aad5;
$golden-yellow: #ffe000;
$selective-yellow: #ffb700;
$sunshade: #fda64f;
$malachite: #00cb6a;
$nasturtium: #fd6042;
$app-background-color: #f2f3f3;
$voltus-border-color: #e6e8e7;
$voltus-internal-bg-color: #C0D6E5;
$voltus-background-color: #f2f3f3;
$voltus-text-color: #36454f;
$voltus-text-muted-color: #445461;
$voltus-link-color: #007e56;
$voltus-link-disabled-color: #686e72;
$voltus-link-color--hover: #003e31;
$voltus-link-color--active: #002921;
$voltus-highlight: #84eab9;
$voltus-error-color: #d3361e;
$voltus-input-disabled-bg: #c8d0cd;
$voltus-input-placeholder-color: #686e72;
$voltus-input-border-color: #d7ddda;
$voltus-form-input-border: #c8d0cd;
$voltus-input-group-addon-bg: #e6e8e7;
$voltus-form-select-disabled-color: #686e72;
$voltus-form-select-bg: #e6e8e7;
$voltus-form-select-disabled-bg: #e6e8e7;
$voltus-form-select-active: #d8edfd;
$voltus-form-select-indicator-color: #373c42;
$voltus-form-range-track-bg: #e6e8e7;
$voltus-form-range-thumb-disabled-bg: #adb8b6;
$voltus-checked-color: #53565a;
$voltus-form-feedback-valid-color: #009460;
$voltus-form-feedback-invalid-color: #ea492d;
$voltus-dropdown-header-color: #686e72;
$voltus-success-alert-color: #84eab9;
$voltus-warning-alert-color: #fcdda6;
$voltus-danger-alert-color: #ffbfb7;
$voltus-breadcrumb-divider-color: #686e72;
$voltus-breadcrumb-active-color: #686e72;
$voltus-icon-color: #7a8597;
$voltus-selected-color: #00a968;
$voltus-button-text--hover: #fff;
$voltus-button-default: #d7ddda;
$voltus-button-default--hover: #686e72;
$voltus-button-default-outline: #686e72;
$voltus-button-default-outline--hover: #0c0e12;
$voltus-button-success: #41d492;
$voltus-button-success--hover: #007e56;
$voltus-button-success-outline: #007e56;
$voltus-button-success-outline--hover: #00543f;
$voltus-button-warning: #f7c36e;
$voltus-button-warning--hover: #e58818;
$voltus-button-warning-outline: #d76900;
$voltus-button-warning-outline--hover: #ae3800;
$voltus-button-danger: #ff9b8c;
$voltus-button-danger--hover: #d3361e;
$voltus-button-danger-outline: #d3361e;
$voltus-button-danger-outline--hover: #951c0d;
$voltus-dark: #536373;
$outline-colors: (primary: #686e72, secondary: #686e72, success: #007e56, info: #686e72, warning: #d76900, danger: #d3361e, light: #686e72, dark: #536373);
$voltus-tooltip-bg: #20242a;
$voltus-hover: #e6e8e7;
$voltus-selected: #41d492;
$voltus-color-success: #41d492;
$voltus-color-info: #00aad5;
$voltus-color-warning: #ea492d;
$chartColors: ((bg: #00CB6A, label: #003E31), (bg: #006450, label: #FFFFFF), (bg: #00CCFF, label: #36454F), (bg: #002150, label: #FFFFFF), (bg: #04B691, label: #20242A), (bg: #234F8D, label: #FFFFFF), (bg: #67A5FF, label: #000000), (bg: #004964, label: #FFFFFF), (bg: #0DE8BB, label: #36454F), (bg: #008080, label: #FFFFFF), (bg: #15004F, label: #FFFFFF), (bg: #8E66FF, label: #111111), (bg: #3204B5, label: #FFFFFF));
$font-regular: 400;
$font-bold: 700;
$font-black: 900;
$font-size-small: 0.75rem;
$h1: 2.25rem;
$h2: 1.85rem;
$h3: 1.5rem;
$h4: 1.35rem;
$h5: 1.25rem;
$h6: 1rem;
$fontSizes: (0: 10px, 1: 12px, 2: 14px, 3: 16px, 4: 18px, 5: 24px, 6: 42px, 7: 64px, 5.5: 30px);
$fontWeights: (regular: 400, bold: 700, black: 900);
$z-index-0: 0;
$z-index-1: 100;
$z-index-2: 200;
$z-index-10: 1000;
$border-radius: 4px;
$screen-med-min: 640px;
$screen-large-min: 1024px;
$max-content-width: 1200px;
$voltus-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
$box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
$shadow_text_small: 0 2px 4px rgba(0, 0, 0, 0.15);
$shadow_toast: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
$space: (0: 0px, 1: 4px, 2: 8px, 3: 16px, 4: 32px, 5: 64px, 6: 128px, 1.5: 6px, 2.5: 12px, 3.5: 24px, 4.5: 40px);
$shadows: (0: 0 1px 3px 0 rgba(0, 0, 0, 0.15), 1: 0 2px 5px 0 rgba(0, 0, 0, 0.35), 2: 0 2px 2px rgba(0, 0, 0, 0.25), focus: 0 0 0 2px #0ebf75);
$radii: (0: 0px, 1: 4px, 2: 8px);
$borders: (0: 1px solid #e6e8e7, 1: 1px solid #adb8b6, 2: 1px solid #36454f, 3: 1px solid #c8d0cd, 4: 1px solid #d7ddda, focus: 1px solid #0ebf75);

@mixin med-screen {
  @media (min-width: #{$screen-med-min}) {
    @content;
  }
}

@mixin large-screen {
  @media (min-width: #{$screen-large-min}) {
    @content;
  }
}

@mixin contentWrapperStyles {
  width: 94%;
  flex-grow: 1;
  max-width: $screen-med-min;
  margin: 0 auto;

  @include med-screen {
    max-width: $max-content-width;
  }
}

@function set-text-color($color) {
  @if ($color == $voltus-dark) {
    @return $color-white; // Lighter backgorund, return dark color
  } @else {
    @return $voltus-text-color; // Darker background, return light color
  }
}

:local {
  .centered {
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
}
