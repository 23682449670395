/*!
 * Font Awesome Pro 5.0.8 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import 'variables';

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url('#{$fa-font-path}/fa-light-300.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-light-300.woff') format('woff'),
    url('#{$fa-font-path}/fa-light-300.eot') format('eot');
}

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}
