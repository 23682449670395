body {
  margin: 0;
  background: $app-background-color;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $voltus-text-color;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: $h1; //2.25
}

h2 {
  font-size: $h2; //1.85
}

h3 {
  font-size: $h3; //1.5
}

h4 {
  font-size: $h4; //1.35
}

h5 {
  font-size: $h5;
}

h6 {
  font-size: $h6;
}

a {
  color: $voltus-link-color;
}

a:hover {
  color: $voltus-link-color--hover;
}

a:disabled {
  color: $voltus-link-disabled-color;
}
